import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import { Loader2 } from "lucide-react"

import { cn } from "@/lib/utils"

// TODO: Button bg gradient
const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-lg font-semibold transition-all duration-200 ease-linear disabled:pointer-events-none",
  {
    variants: {
      variant: {
        primary:
          "bg-brand-600 text-white hover:bg-brand-700 disabled:border disabled:border-disabled-subtle disabled:bg-disabled disabled:text-fg-disabled disabled:shadow-none",
        secondary:
          "border border-gray-300 bg-white text-secondary hover:bg-gray-50 disabled:border-disabled-subtle disabled:text-fg-disabled",
        tertiary:
          "text-gray-600 hover:bg-gray-50 hover:text-gray-700 disabled:border-none disabled:bg-transparent disabled:text-fg-disabled",
        link: "text-gray-600 hover:text-gray-700 hover:underline disabled:border-none disabled:bg-transparent disabled:text-fg-disabled disabled:no-underline",
        linkColor:
          "text-brand-700 hover:text-brand-800 hover:underline disabled:border-none disabled:bg-transparent disabled:text-fg-disabled disabled:no-underline",
        error:
          "bg-error-600 text-white hover:bg-error-700 disabled:border-disabled-subtle disabled:bg-disabled disabled:text-fg-disabled",
        theme: "bg-theme-fg-primary text-white",
        paginate: "rounded-full bg-brand-secondary text-brand-tertiary",
      },
      size: {
        sm: "h-9 gap-1 px-3 py-2",
        md: "h-10 gap-1.5 px-[14px] py-[10px]",
        lg: "h-11 gap-1.5 px-4 py-[10px]",
        xl: "h-12 gap-1.5 px-[18px] py-3",
        "2xl": "gap-[10px] px-[22px] py-3",
        "icon-sm": "size-9 p-2",
        "icon-md": "size-10 p-[10px]",
        "icon-lg": "size-11 p-3",
        "icon-xl": "size-12 p-[14px]",
        "icon-2xl": "size-14 p-4",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "md",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  isLoading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, asChild = false, isLoading = false, size, children, disabled, ...rest }, ref) => {
    const Comp = asChild ? Slot : "button"

    return (
      <Comp
        className={cn(buttonVariants({ size, variant, className }))}
        ref={ref}
        disabled={isLoading || disabled}
        {...rest}
      >
        {isLoading ? (
          <>
            <Loader2 className="mr-2 size-4 animate-spin" />
            <span className="opacity-70">{typeof children === "string" ? children : "Loading..."}</span>
          </>
        ) : (
          children
        )}
      </Comp>
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
